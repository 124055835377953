import { completed } from "./completed"
import { documents } from "./documents"
import { start } from "./start"

export enum SIGN_UP_EVENTS {
  START = "User begins sign up",
  UPLOAD_DOCUMENT_ERROR = "User uploads document with error",
  UPLOAD_DOCUMENT_SUCCESS = "User uploads document successfully",
  UPLOAD_DOCUMENT_OMITTED = "User omits document upload",
  COMPLETED = "User successfully signs up",
}

export const signUp = {
  start,
  documents,
  completed,
}
