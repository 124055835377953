import { client } from "@/config/client"

export interface GetPlanResponse {
  status: string
  detail: string
  plans: AvailablePlan[]
}

enum PLAN_TYPE {
  FREE = "FREE",
  BASIC = "BASIC",
  MONTHLY_INDIVIDUAL = "MONTHLY_INDIVIDUAL",
  YEARLY_INDIVIDUAL = "YEARLY_INDIVIDUAL",
  YEARLY_FAMILY = "YEARLY_FAMILY",
  GIFT = "GIFT",
}

export interface AvailablePlan {
  id: string
  name: string
  duration: "INFINITY" | "ONE_MONTH" | "ONE_YEAR"
  paymentMethods: PaymentMethod[] | null
  best: boolean
  type: PLAN_TYPE
  items: string[]
}

export interface PaymentMethod {
  id: string
  type: "MP" | "STRIPE"
  price: number
  currency: "ARS" | "USD"
  externalPlanId: string
}

export function getAll() {
  return client.get<GetPlanResponse>("/v1/plans")
}
