import mixpanel from "mixpanel-browser"
import { PAYMENT_EVENTS } from "."

interface SeePlansProps {
  userId: string
  name: string
  email: string
  country: string
  actualPlan: string
}

export function seePlans(props: SeePlansProps) {
  mixpanel.track(PAYMENT_EVENTS.SEE_PLANS, props)
}
