import type { LucideIcon } from "lucide-react"
import { HomeIcon, UserIcon, TestTube, PencilRuler } from "lucide-react"
import { buttonVariants } from "@/components/ui/button"
import { Link, useLocation } from "react-router-dom"
import { cn } from "@/lib/utils"
import TitoButton from "@/modules/tito/components/tito-button"

interface NavLinkProps {
  to: string
  icon: LucideIcon
  label: string
  isActive: boolean
}

const NavLink = ({ to, icon: Icon, label, isActive }: NavLinkProps) => (
  <Link
    to={to}
    className={cn(
      buttonVariants({
        variant: "ghost",
      }),
      "flex h-fit flex-col gap-1 py-2 text-black/60",
      isActive && "text-primary-300",
    )}
  >
    <Icon className="!size-6" />
    <span className="text-xs">{label}</span>
  </Link>
)

export function MobileNavbar() {
  const { pathname } = useLocation()

  return (
    <div className="fixed bottom-0 left-0 right-0 z-50 border-t bg-background">
      <nav className="grid h-16 grid-cols-5 place-items-center gap-1 px-4">
        <NavLink
          to="/dashboard"
          icon={HomeIcon}
          label="Inicio"
          isActive={pathname === "/dashboard"}
        />
        <NavLink
          to="/diagnoses"
          icon={TestTube}
          label="Diagnóstico"
          isActive={pathname === "/diagnoses"}
        />

        <TitoButton className="max-[425px]:-mt-8 -mt-6" />

        <NavLink
          to="/tools"
          icon={PencilRuler}
          label="Herramientas"
          isActive={pathname === "/tools"}
        />
        <NavLink
          to="/my-profile"
          icon={UserIcon}
          label="Perfil"
          isActive={pathname === "/my-profile"}
        />
      </nav>
    </div>
  )
}
