import { useUser } from "../stores"
import { PLAN_TYPE } from "@/services/api/users/info/get"
import { useEffect, useState } from "react"

export const useFinishFreeTrial = () => {
  const [isTrialFinished, setIsTrialFinished] = useState(false)
  const { user } = useUser()

  useEffect(() => {
    if (user.activePlan.type === PLAN_TYPE.FREE && user.lives <= 0) {
      setIsTrialFinished(true)
      return
    }
  }, [user.email, user.activePlan.type, user.lives])

  return { isTrialFinished }
}
