import type { Review } from "@/services/api/challenges/get-session-review"
import { SquareCheck } from "lucide-react"
import { useNavigate } from "react-router-dom"
import useCanContinueLearning from "@/modules/user/hooks/use-can-continue-learning"
import { useNoLifeAlert } from "@/modules/app/stores/use-no-life-alert"
import mixpanelClient from "@/services/mixpanel"
import { useLearningPaths } from "@/modules/app/stores/use-lp-store"
import { useEffect } from "react"
import { isApproved } from "../../utils/score"

interface ReviewLayoutProps {
  review: Review
  currentLevelId: string
}

const ReviewLayout = ({ review, currentLevelId }: ReviewLayoutProps) => {
  const { title, subtitle, score, reviews, nextChallengeLevelId } = review
  const navigate = useNavigate()
  const canContinueLearning = useCanContinueLearning()
  const { setIsOpen: openNoLifeAlert } = useNoLifeAlert()
  const { selectedLearningPath } = useLearningPaths()

  const handleOnClick = (levelId: string) => {
    if (canContinueLearning) {
      navigate(`/challenges/${levelId}`)
    } else {
      openNoLifeAlert(true)
    }
  }

  function onNextLevel(levelId: string) {
    mixpanelClient.challenges.nextLevel({
      subject: selectedLearningPath.name,
    })
    handleOnClick(levelId)
  }
  function onRepeatLevel(levelId: string) {
    mixpanelClient.challenges.repeatLevel({
      score: Math.trunc(score),
      subject: selectedLearningPath.name,
    })
    handleOnClick(levelId)
  }

  useEffect(() => {
    mixpanelClient.challenges.completeLevel({
      score: Math.trunc(score),
      subject: selectedLearningPath.name,
      approved: isApproved(score),
      endDate: new Date().toISOString(),
    })
  }, [])

  return (
    <div>
      <div className="mx-auto mt-3 max-w-[800px] px-4">
        <div className="mb-[60px] flex flex-col items-center justify-center">
          <ProgressCircle percentage={Math.trunc(score)} />
          <h6 className="mb-3 mt-5 text-center text-3xl font-medium text-orange-500">{title}</h6>
          <h6 className="mx-auto w-[80%] text-center text-base font-medium text-gray-500">
            {subtitle}
          </h6>
        </div>

        <div>
          <h6 className="mb-2 text-orange-500">¿Qué podés mejorar?</h6>

          <div className="flex flex-col gap-4 pb-[160px] md:pb-0">
            {reviews.map((reviewText, i) => (
              <div className="flex items-start justify-start gap-2" key={i}>
                <SquareCheck className="shrink-0 text-orange-500" />

                <p className="text-base font-normal leading-6 first-line:leading-none">
                  {reviewText}
                </p>
              </div>
            ))}
          </div>
        </div>

        <div className="fixed bottom-0 left-0 z-[100] flex w-full flex-col gap-3 bg-amber-50 p-5 md:static md:my-10 md:p-0">
          {nextChallengeLevelId && (
            <button
              type="button"
              onClick={() => onNextLevel(nextChallengeLevelId)}
              className="rounded-[8px] bg-orange-500 p-2 text-center text-sm text-white"
            >
              Siguiente nivel
            </button>
          )}

          <button
            type="button"
            onClick={() => onRepeatLevel(currentLevelId)}
            className="rounded-[8px] border border-gray-400 bg-white p-2 text-center text-sm text-black"
          >
            Repetir nivel
          </button>
        </div>
      </div>
    </div>
  )
}

const ProgressCircle = ({ percentage }: { percentage: number }) => {
  const radius = 16
  const circumference = 2 * Math.PI * radius
  const offset = circumference - (percentage / 100) * circumference

  return (
    <div className="relative flex size-[272px] items-center justify-center">
      <svg className="size-full -rotate-90" viewBox="0 0 40 40">
        <circle cx="20" cy="20" r={radius} fill="none" stroke="#E5E7EB" strokeWidth="5" />

        <circle
          cx="20"
          cy="20"
          r={radius}
          fill="none"
          stroke="#FB923C"
          strokeWidth="5"
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          strokeLinecap="round"
          style={{
            stroke: "url(#customGradient)",
          }}
        />

        <defs>
          <linearGradient id="customGradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#F97316" />
            <stop offset="100%" stopColor="#FB923C" />
          </linearGradient>
        </defs>
      </svg>

      <span className="absolute text-7xl font-bold">
        {percentage}
        <span className="text-4xl font-normal text-gray-500">%</span>
      </span>
    </div>
  )
}

export default ReviewLayout
