import { client } from "@/config/client"
import type { ActivePlan } from "./info/get"

export interface UserEvent {
  id: string
  userId: string
  event: string
  createdAt: string
}

export interface User {
  id: number
  avatar: string
  name: string
  email: string
  country: string
  university: string
  career: string
  lives: number
  plan: "FREE" | "PRO"
  activePlan: ActivePlan
  events: UserEvent[]
}

interface GetUserResponse {
  total: number
  users: User[]
}

interface GetUserParams {
  search: string
  offset: number
  limit: number
  plan: string
}

export function getAll(params: Partial<GetUserParams>) {
  return client.get<GetUserResponse>("/v1/users", { params })
}
