import getCancelOptions from "./get-cancel-options"
import cancelSubscription from "./cancel-subscription"
import sendCancellationFeedback from "./send-cancellation-feedback"
import { processMercadoPago } from "./process-mp"
import { processStripe } from "./process-stripe"
import { uniquePayment } from "./unique-pay"

export const payments = {
  processMercadoPago,
  processStripe,
  getCancelOptions,
  cancelSubscription,
  sendCancellationFeedback,
  uniquePayment,
}
