import mixpanel from "mixpanel-browser"
import { CHALLENGES_EVENTS } from "."

interface RepeatLevelProps {
  subject: string
  score: number
}

export function repeatLevel(props: RepeatLevelProps) {
  mixpanel.track(CHALLENGES_EVENTS.REPEAT_LEVEL, props)
}
