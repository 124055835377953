import { client } from "@/config/client"

export interface GetStudyAreasParams {
  level: string
}
export interface StudyArea {
  id: string
  role: "UNIVERSITY" | "SECONDARY"
  name: string
}

export interface StudyYear {
  id: string
  role: "UNIVERSITY" | "TERTIARY"
  name: string
}

interface GetStudyAreasResponse {
  description: {
    studyAreas: StudyArea[]
    years: StudyYear[]
  }
}

export function getStudyAreas(params: GetStudyAreasParams) {
  return client.get<GetStudyAreasResponse>("/v1/study-areas", {
    params,
  })
}
