import { client } from "@/config/client"

export type UniquePayRequest = {
  type: "STRIPE" | "MP"
}

export interface UniquePayResponse {
  status: string
  detail: string
  initPoint: string
}

export function uniquePayment(data: UniquePayRequest) {
  return client.post<UniquePayResponse, UniquePayRequest>(`/v1/payments/basic`, data)
}
