import mixpanel from "mixpanel-browser"
import { CHALLENGES_EVENTS } from "."

interface CompleteLevelProps {
  endDate: string
  subject: string
  score: number
  approved: boolean
}

export function completeLevel(props: CompleteLevelProps) {
  mixpanel.track(CHALLENGES_EVENTS.COMPLETE_LEVEL, props)
}
