import mixpanel from "mixpanel-browser"
import { CHALLENGES_EVENTS } from "."

interface StartLevelProps {
  startDate: string
  subject: string
}

export function startLevel(props: StartLevelProps) {
  mixpanel.track(CHALLENGES_EVENTS.START_LEVEL, props)
}
