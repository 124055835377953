import { explorePremium } from "./explore-premium"
import { seePlans } from "./see-plans"

export enum PAYMENT_EVENTS {
  EXPLORE_PREMIUM = "User press 'explore premium' from end free trial modal",
  SEE_PLANS = "User press see plans from 'Get tich premium' modal",
}

export const payments = {
  explorePremium,
  seePlans,
}
