import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Badge } from "@/components/ui/badge"
import { Settings, LogOut, FileText, BookOpen, UserCircle, Crown } from "lucide-react"
import { useAuth } from "@/modules/auth/stores"
import { useNavigate } from "react-router-dom"
import { resetAllStores } from "@/utils/reset-states"
import { useUser } from "@/modules/user/stores"
import useUserPlan from "@/modules/user/hooks/use-user-plan"

export default function UserMenu() {
  const { user } = useUser()
  const { logOut } = useAuth()
  const router = useNavigate()
  const { isPro } = useUserPlan()

  const handleOnClickSubjects = () => {
    router("/learning-paths")
  }

  function onLogout() {
    resetAllStores()
    logOut()
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className="relative">
          <Avatar className="size-9 cursor-pointer">
            <AvatarImage src={user.avatar} className="h-full w-full object-cover" />
            <AvatarFallback className="font-regular bg-primary text-lg text-white">
              {user.name.charAt(0)}
            </AvatarFallback>
          </Avatar>
          {isPro && (
            <div className="absolute -right-2.5 -top-1 rounded-full bg-gradient-to-r from-fuchsia-500 to-fuchsia-600 p-1 text-white shadow-lg">
              <Crown className="h-3 w-3" strokeWidth={2} />
            </div>
          )}
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="mr-3 mt-2 p-2">
        <div className="flex items-start justify-between px-2">
          <div className="flex flex-col space-y-1">
            <div className="flex items-center gap-2">
              <p className="truncate text-lg font-medium">{user.name}</p>
              {isPro && (
                <Badge
                  variant="default"
                  className="bg-gradient-to-r from-fuchsia-500 to-fuchsia-600 font-medium text-white"
                >
                  <Crown className="mr-1.5 h-3 w-3" strokeWidth={2} />
                  Premium
                </Badge>
              )}
            </div>
            <p className="truncate text-sm text-gray-500">{user.email}</p>
          </div>
        </div>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={() => router("/documents")}>
          <FileText className="mr-2 h-4 w-4" />
          Mis documentos
        </DropdownMenuItem>
        <DropdownMenuItem onClick={handleOnClickSubjects}>
          <BookOpen className="mr-2 h-4 w-4" />
          Mis materias
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={() => router("/my-profile")}>
          <UserCircle className="mr-2 h-4 w-4" />
          Mi perfil
        </DropdownMenuItem>
        <DropdownMenuItem disabled>
          <Settings className="mr-2 h-4 w-4" />
          Configuración
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={onLogout}>
          <LogOut className="mr-2 h-4 w-4" />
          Cerrar sesión
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
