import Stepper from "./stepper"
import { Button } from "@/components/ui/button"
import usePresentationStepper from "../../stores/use-presentation"
import ActivityDashboardCard from "@/modules/dashboard/components/activity-card"

export default function PresentationStepThree() {
  const { step, setStep } = usePresentationStepper()

  return (
    <section className="mx-auto max-w-7xl">
      {/* DESKTOP */}
      <article className="hidden px-20 lg:block">
        <Stepper />
        <section className="flex h-[80vh] items-center gap-16">
          <section className="flex flex-1 flex-col gap-3">
            <h1 className="w-[25ch] text-2xl font-bold text-black/85">
              Todo lo que necesitás para aprobar en un solo lugar
            </h1>
            <Button className="mt-12 w-fit" onClick={() => setStep(step + 1)}>
              Empezar
            </Button>
          </section>
          <section className="grid flex-1 grid-rows-3 gap-8">
            <ActivityDashboardCard
              hideArrow
              title="Desafíos"
              description="Poné a prueba tus conocimientos como si fuese un juego. Subí tu pdf y respondé preguntas."
              variant="challenge"
              classNames="lg:h-[160px]"
              imageClassName="lg:bottom-14"
            />
            <ActivityDashboardCard
              hideArrow
              title="Flashcards"
              description="Recordá conceptos clave con este método de repetición espaciada y combatí la curva del olvido."
              variant="flashcard"
              classNames="lg:h-[160px]"
              imageClassName="lg:bottom-14"
            />
            <ActivityDashboardCard
              hideArrow
              title="Asistente personal"
              description="Organizá tu estudio y recibí explicaciones personalizadas para tu carrera. Tito será tu nuevo mejor amigo."
              variant="assistant"
              classNames="lg:h-[160px]"
              imageClassName="lg:bottom-14"
            />
          </section>
        </section>
        {/* <p className="text-sm text-black/60">
          ¿Saber más?{" "}
          <Link to="" className="text-sm font-bold text-primary">
            Descubrí por qué Tich tiene resultados
          </Link>
        </p> */}
      </article>
      {/* MOBILE */}
      <article className="space-y-2 px-2 lg:hidden">
        <h1 className="mx-auto w-[20ch] text-center text-2xl font-bold text-black/85">
          Todo lo que necesitás para aprobar en un solo lugar
        </h1>
        <section className="grid grid-rows-3 gap-2">
          <ActivityDashboardCard
            hideArrow
            title="Desafíos"
            description="Poné a prueba tus conocimientos como si fuese un juego. Subí tu pdf y respondé preguntas."
            variant="challenge"
            imageClassName="!bottom-12"
          />
          <ActivityDashboardCard
            hideArrow
            title="Flashcards"
            description="Recordá conceptos clave con este método de repetición espaciada y combatí la curva del olvido."
            variant="flashcard"
            imageClassName="!bottom-12"
          />
          <ActivityDashboardCard
            hideArrow
            title="Asistente personal"
            description="Organizá tu estudio y recibí explicaciones personalizadas para tu carrera. Tito será tu nuevo mejor amigo."
            variant="assistant"
            imageClassName="!bottom-12"
          />
        </section>
      </article>
      <article className="mt-14 flex flex-col items-center space-y-6 px-2 pb-14 lg:hidden">
        <Stepper />
        <Button className="w-full" onClick={() => setStep(step + 1)}>
          Empezar
        </Button>
        {/* <p className="text-black/60">
          ¿Saber más?{" "}
          <Link to="" className="font-bold text-primary">
            Descubrí por qué Tich tiene resultados
          </Link>
        </p> */}
      </article>
    </section>
  )
}
