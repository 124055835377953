import { useEffect, useMemo, useState } from "react"
import type { SortingState, ColumnFiltersState, PaginationState } from "@tanstack/react-table"
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  flexRender,
  createColumnHelper,
} from "@tanstack/react-table"
import { ArrowUpDown, ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight } from "lucide-react"
import { Button } from "@/components/ui/button"
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import { Input } from "@/components/ui/input"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import type { User } from "@/services/api/users/getAll"
import { Loader } from "@/components/loader"
import UserActionsMenu from "./users-actions"
import { cn } from "@/lib/utils"

export interface UserTableProps {
  search: string
  total: number
  users: User[]
  isLoading: boolean
  onEdit: (user: User) => void
  onLoginAs: (user: User) => void
  onSearchChange: (search: string) => void
  onPageChange: (offset: number, limit: number) => void
  onPlanChange: (plan: string) => void
}

export default function UsersTable({
  search,
  users,
  total,
  isLoading,
  onEdit,
  onLoginAs,
  onPageChange,
  onSearchChange,
  onPlanChange,
}: UserTableProps) {
  const [sorting, setSorting] = useState<SortingState>([])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  })

  const columnHelper = createColumnHelper<User>()

  const columns = useMemo(
    () => [
      columnHelper.accessor("avatar", {
        header: "",
        cell: (info) => (
          <Avatar className="size-8">
            <AvatarImage src={info.getValue()} alt="User avatar" />
            <AvatarFallback className="text-xs">
              {info.row.original.name.slice(0, 2).toUpperCase()}
            </AvatarFallback>
          </Avatar>
        ),
        enableSorting: false,
      }),
      columnHelper.accessor("name", {
        header: ({ column }) => (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Nombre
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("email", {
        header: "Email",
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("country", {
        header: "País",
        cell: (info) => info.getValue()?.replace(/_|-/g, " "),
      }),
      columnHelper.accessor("university", {
        header: "Universidad",
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("career", {
        header: "Carrera",
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("activePlan", {
        header: "Plan activo",
        cell: (info) => {
          const activePlan = info.getValue()
          return activePlan ? activePlan.name : "-"
        },
      }),
      columnHelper.accessor("lives", {
        header: "Vidas",
        cell: (info) => <div className="text-center">{info.getValue()}</div>,
      }),
      columnHelper.display({
        id: "actions",
        header: "Acciones",
        cell: (item) => (
          <div className="flex justify-center">
            <UserActionsMenu
              onEdit={() => onEdit(item.row.original)}
              onLoginAs={() => onLoginAs(item.row.original)}
            />
          </div>
        ),
      }),
    ],
    [],
  )

  const table = useReactTable({
    data: users,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onPaginationChange: setPagination,
    pageCount: Math.ceil(total / pagination.pageSize),
    state: {
      sorting,
      columnFilters,
      pagination,
    },
    manualPagination: true,
  })

  useEffect(() => {
    onPageChange(pagination.pageIndex * pagination.pageSize, pagination.pageSize)
  }, [pagination.pageIndex, pagination.pageSize, onPageChange])

  const renderTableContent = () => {
    if (isLoading) {
      return (
        <TableRow>
          <TableCell colSpan={columns.length} className="h-[400px]">
            <div className="flex h-full items-center justify-center">
              <Loader />
            </div>
          </TableCell>
        </TableRow>
      )
    }

    if (!users.length) {
      return (
        <TableRow>
          <TableCell colSpan={columns.length} className="h-[400px] text-center">
            <div className="flex h-full flex-col items-center justify-center">
              <p className="mb-2 text-2xl text-gray-500">No hay usuarios</p>
              <p className="text-sm text-gray-400">Intenta ajustar tu búsqueda</p>
            </div>
          </TableCell>
        </TableRow>
      )
    }

    return table.getRowModel().rows.map((row) => (
      <TableRow
        key={row.id}
        data-state={row.getIsSelected() && "selected"}
        className="bg-white hover:bg-gray-50"
      >
        {row.getVisibleCells().map((cell) => (
          <TableCell key={cell.id} className={cn(cell.column.id === "country" && "capitalize")}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </TableCell>
        ))}
      </TableRow>
    ))
  }

  return (
    <div className="w-full rounded-lg p-4 shadow-sm">
      <div className="mb-4 flex items-center justify-end space-x-4">
        <Select
          onValueChange={(e) => {
            onPlanChange(e)
            setPagination({ ...pagination, pageIndex: 0 })
          }}
          defaultValue="ALL"
        >
          <SelectTrigger className="w-fit pl-4">
            <SelectValue placeholder="Tipo" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="ALL">Todos</SelectItem>
            <SelectItem value="FREE">Free</SelectItem>
            <SelectItem value="PRO">Premium</SelectItem>
          </SelectContent>
        </Select>
        <Input
          placeholder="Buscar por nombre, email, universidad..."
          value={search}
          onChange={(event) => onSearchChange(event.target.value)}
          className="max-w-sm"
        />
      </div>
      <div className="overflow-hidden rounded-md border bg-white shadow-md">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead className="bg-gray-100 font-medium text-black" key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>{renderTableContent()}</TableBody>
        </Table>
      </div>
      {!isLoading && users.length > 0 && (
        <div className="flex items-center justify-end space-x-2 py-4">
          <div className="flex items-center space-x-2">
            <Button
              variant="outline"
              size="sm"
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            >
              <ChevronsLeft className="h-4 w-4" />
            </Button>
            <Button
              variant="outline"
              size="sm"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              <ChevronLeft className="h-4 w-4" />
            </Button>
            <div className="text-sm">
              Página {table.getState().pagination.pageIndex + 1} de {table.getPageCount()}
            </div>
            <Button
              variant="outline"
              size="sm"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              <ChevronRight className="h-4 w-4" />
            </Button>
            <Button
              variant="outline"
              size="sm"
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            >
              <ChevronsRight className="h-4 w-4" />
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}
