import { client } from "@/config/client"

export type ProcessStripeParams = {
  planId: string
}

export interface ProcessStripeResponse {
  status: string
  detail: string
  sessionId: string
}

export function processStripe(data: ProcessStripeParams) {
  return client.post<ProcessStripeResponse, ProcessStripeParams>(`/v1/payments/stripe`, data)
}
