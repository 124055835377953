import mixpanel from "mixpanel-browser"
import { PAYMENT_EVENTS } from "."

interface ExplorePremiumProps {
  userId: string
  name: string
  email: string
  country: string
  actualPlan: string
}

export function explorePremium(props: ExplorePremiumProps) {
  mixpanel.track(PAYMENT_EVENTS.EXPLORE_PREMIUM, props)
}
