import { createFlashcard } from "./create-flashcard"
import { startFlashcard } from "./start-flashcard"
import { completeFlashcard } from "./complete-flashcard"
import { moreFlashcards } from "./more-flashcards"

export enum FLASHCARDS_EVENTS {
  CREATE_FLASHCARD = "User creates a flashcard",
  START_FLASHCARD = "User starts a flashcard",
  COMPLETE_FLASHCARD = "User completes a flashcard",
  MORE_FLASHCARDS = "User wants more flashcards",
}

export const flashcards = {
  createFlashcard,
  startFlashcard,
  completeFlashcard,
  moreFlashcards,
}
