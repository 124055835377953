import useBugSnag from "@/app/hooks/useBugSnag"
import { api } from "@/services/api"
import type { ProcessStripeParams } from "@/services/api/payments/process-stripe"
import { useMutation } from "@tanstack/react-query"

export const useProcessStripe = () => {
  const { notify } = useBugSnag()

  const mutation = useMutation({
    mutationKey: ["process-stripe-payment"],
    mutationFn: (params: ProcessStripeParams) => api.payments.processStripe(params),
  })
  async function handleStripeProcess(planId: string) {
    try {
      const { data } = await mutation.mutateAsync({ planId })
      if (data.sessionId) {
        window.open(data.sessionId, "_self")
      }
    } catch (error) {
      notify(error)
    }
  }
  return { processStripe: mutation, handleStripeProcess }
}
