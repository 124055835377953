import { Button } from "@/components/ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { cn } from "@/lib/utils"
import { ArrowRight } from "lucide-react"

interface ActivityCardProps {
  title: string
  description: string
  variant: "challenge" | "flashcard" | "assistant"
  handleOnClick?: () => void
  hideArrow?: boolean
  classNames?: string
  imageClassName?: string
}

export default function ActivityDashboardCard({
  title,
  description,
  handleOnClick,
  variant,
  hideArrow = false,
  classNames = "",
  imageClassName = "",
}: ActivityCardProps) {
  function handleSourceImage() {
    switch (variant) {
      case "challenge":
        return "https://storage.googleapis.com/educabot-aec-cdn-1/tich/images/challenge_card_image.png"
      case "flashcard":
        return "https://storage.googleapis.com/educabot-aec-cdn-1/tich/images/flashcards_card_image.png"
      case "assistant":
        return "https://storage.googleapis.com/educabot-aec-cdn-1/tich/images/assistant_card_image.png"
    }
  }

  return (
    <Card
      className={cn(
        "relative h-full w-full cursor-default overflow-hidden border-none bg-orange-100 shadow-inner before:absolute before:inset-0 before:z-10 before:bg-gradient-to-t before:from-orange-100/25 before:to-white/25 lg:h-[230px] lg:max-h-[264px]",
        handleOnClick && "cursor-pointer",
        classNames,
      )}
      onClick={handleOnClick}
    >
      <CardHeader className="p-5 pb-0">
        <CardTitle className="z-10 text-lg font-medium text-primary-600">{title}</CardTitle>
      </CardHeader>
      <CardContent className="relative z-20 flex justify-between p-5 pt-2">
        <span className="min-[415px]:w-[235px] w-[215px] text-sm text-muted-foreground">
          {description}
        </span>
        {!hideArrow && (
          <div className="!size-10 self-end">
            <Button
              size="icon"
              className="rounded-lg bg-[#FDBA74] lg:hidden"
              onClick={handleOnClick}
            >
              <ArrowRight className="!h-6 !w-6 text-white" />
            </Button>
          </div>
        )}
      </CardContent>
      <img
        className={cn(
          "absolute bottom-0 right-0 z-[11] -mb-1 w-32 lg:-mb-16 lg:w-44",
          imageClassName,
        )}
        src={handleSourceImage()}
      />
    </Card>
  )
}
