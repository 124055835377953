import { client } from "@/config/client"

export interface CancelOption {
  id: string
  name: string
}

export interface GetCancelOptionsResponse {
  status: string
  details: string
  cancelOptions: CancelOption[]
}

const getCancelOptions = () => {
  return client.get<GetCancelOptionsResponse>("/v1/payments/cancel/options")
}

export default getCancelOptions
