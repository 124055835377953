import { useEffect } from "react"
import { useForm, Controller } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { Button } from "@/components/button"
import { Input } from "@/components/ui/input"
import { Sheet, SheetContent, SheetHeader, SheetClose } from "@/components/ui/sheet"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import type { User } from "@/services/api/users/getAll"
import { AvatarFallback, AvatarImage } from "@radix-ui/react-avatar"
import { Avatar } from "@/components/ui/avatar"
import type { EditUserSchema } from "../validations/edit-user"
import { editUserSchema } from "../validations/edit-user"
import { FormItem } from "@/components/ui/form"
import { Switch } from "@/components/ui/switch"
import { PLAN_TYPE } from "@/services/api/users/info/get"

const countries = [
  { value: "No definido", label: "No definido" },
  { value: "argentina", label: "Argentina" },
  { value: "bolivia", label: "Bolivia" },
  { value: "brasil", label: "Brasil" },
  { value: "chile", label: "Chile" },
  { value: "colombia", label: "Colombia" },
  { value: "costa_rica", label: "Costa Rica" },
  { value: "cuba", label: "Cuba" },
  { value: "ecuador", label: "Ecuador" },
  { value: "el_salvador", label: "El Salvador" },
  { value: "españa", label: "España" },
  { value: "guatemala", label: "Guatemala" },
  { value: "honduras", label: "Honduras" },
  { value: "mexico", label: "México" },
  { value: "nicaragua", label: "Nicaragua" },
  { value: "panama", label: "Panamá" },
  { value: "paraguay", label: "Paraguay" },
  { value: "peru", label: "Perú" },
  { value: "puerto_rico", label: "Puerto Rico" },
  { value: "republica_dominicana", label: "República Dominicana" },
  { value: "uruguay", label: "Uruguay" },
  { value: "venezuela", label: "Venezuela" },
]

interface AdminEditUserSheetProps {
  user: User
  open: boolean
  isLoading: boolean
  setOpen: (open: boolean) => void
  onSave: (userId: number, data: EditUserSchema) => void
}

export default function AdminEditUserSheet({
  user,
  open,
  isLoading,
  setOpen,
  onSave,
}: AdminEditUserSheetProps) {
  const defaultValues: EditUserSchema = {
    country: user.country ?? "No definido",
    lives: user.lives ?? 0,
    gifted: user.activePlan.type === PLAN_TYPE.GIFT,
  }

  const notAllowedRoles = [
    PLAN_TYPE.MONTHLY_INDIVIDUAL,
    PLAN_TYPE.YEARLY_FAMILY,
    PLAN_TYPE.YEARLY_INDIVIDUAL,
  ]

  const form = useForm<EditUserSchema>({
    mode: "onChange",
    resolver: zodResolver(editUserSchema),
    defaultValues,
  })

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    formState: { errors, isValid },
  } = form

  useEffect(() => {
    if (open) {
      reset(defaultValues)
    }
  }, [open, user, reset])

  const handleOnSubmit = () => {
    const values = getValues()
    if (isValid) {
      onSave(user.id, values)
    }
  }

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetContent side="right" className="inset-y-0 flex h-auto w-[400px] flex-col p-0">
        <SheetHeader className="border-b p-4">
          <div className="space-y-2 py-6 pl-4">
            <h2 className="flex items-center gap-2 text-lg font-semibold">
              <Avatar className="size-8">
                <AvatarImage src={user.avatar} alt="User avatar" />
                <AvatarFallback className="text-xs">
                  {user.name.slice(0, 2).toUpperCase()}
                </AvatarFallback>
              </Avatar>{" "}
              {user.name}
            </h2>
            <p className="text-sm font-medium text-gray-900">{user.email}</p>
          </div>
        </SheetHeader>
        <form {...form} className="flex flex-col gap-4 p-4">
          <div className="flex flex-col items-start gap-2">
            <span className="text-sm font-medium">Plan</span>
            <Controller
              control={control}
              name="gifted"
              disabled={notAllowedRoles.includes(user.activePlan.type)}
              render={({ field }) => (
                <FormItem className="flex w-full flex-row items-center justify-between rounded-lg border p-3 shadow-sm">
                  <div className="space-y-0.5">
                    <label>Regalo</label>
                  </div>
                  <Switch
                    disabled={notAllowedRoles.includes(user.activePlan.type)}
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormItem>
              )}
            />
            {errors.gifted && <p className="text-sm text-red-500">{errors.gifted.message}</p>}
          </div>
          <div className="flex flex-col items-start gap-2">
            <span className="text-sm font-medium">País</span>
            <Controller
              name="country"
              control={control}
              render={({ field }) => (
                <Select value={field.value} onValueChange={field.onChange}>
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="Seleccionar país" />
                  </SelectTrigger>
                  <SelectContent className="z-50 max-h-96 overflow-auto">
                    {countries.map((country, index) => (
                      <SelectItem key={index} value={country.value}>
                        {country.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              )}
            />
            {errors.country && <p className="text-sm text-red-500">{errors.country.message}</p>}
          </div>
          <div className="flex flex-col items-start gap-2">
            <span className="text-sm font-medium">Vidas</span>
            <Controller
              name="lives"
              control={control}
              render={({ field }) => (
                <Input
                  type="number"
                  {...field}
                  onChange={(e) => field.onChange(parseInt(e.target.value, 10) || 0)}
                />
              )}
            />
            {errors.lives && <p className="text-sm text-red-500">{errors.lives.message}</p>}
          </div>
          <SheetClose>
            <div className="mt-2 flex w-full justify-end">
              <Button
                type="submit"
                onClick={handleSubmit(handleOnSubmit)}
                variant="default"
                className="w-fit"
                loading={isLoading}
              >
                Guardar
              </Button>
            </div>
          </SheetClose>
        </form>
      </SheetContent>
    </Sheet>
  )
}
