const countries = [
  {
    value: "argentina",
    label: "Argentina",
    image: "https://storage.googleapis.com/educabot-aec-cdn-1/tich/flags/argentina.svg",
    code: "AR",
    phone: "+54",
  },
  {
    value: "brasil",
    label: "Brasil",
    image: "https://storage.googleapis.com/educabot-aec-cdn-1/tich/flags/brasil.svg",
    code: "BR",
    phone: "+55",
  },
  {
    value: "bolivia",
    label: "Bolivia",
    image: "https://storage.googleapis.com/educabot-aec-cdn-1/tich/flags/bolivia.svg",
    code: "BO",
    phone: "+591",
  },
  {
    value: "chile",
    label: "Chile",
    image: "https://storage.googleapis.com/educabot-aec-cdn-1/tich/flags/chile.svg",
    code: "CL",
    phone: "+56",
  },
  {
    value: "colombia",
    label: "Colombia",
    image: "https://storage.googleapis.com/educabot-aec-cdn-1/tich/flags/colombia.svg",
    code: "CO",
    phone: "+57",
  },
  {
    value: "costa_rica",
    label: "Costa Rica",
    image: "https://storage.googleapis.com/educabot-aec-cdn-1/tich/flags/costa-rica.svg",
    code: "CR",
    phone: "+506",
  },
  {
    value: "cuba",
    label: "Cuba",
    image: "https://storage.googleapis.com/educabot-aec-cdn-1/tich/flags/cuba.svg",
    code: "CU",
    phone: "+53",
  },
  {
    value: "ecuador",
    label: "Ecuador",
    image: "https://storage.googleapis.com/educabot-aec-cdn-1/tich/flags/ecuador.svg",
    code: "EC",
    phone: "+593",
  },
  {
    value: "el_salvador",
    label: "El Salvador",
    image: "https://storage.googleapis.com/educabot-aec-cdn-1/tich/flags/el-salvador.svg",
    code: "SV",
    phone: "+503",
  },
  {
    value: "espana",
    label: "España",
    image: "https://storage.googleapis.com/educabot-aec-cdn-1/tich/flags/spain.svg",
    code: "ES",
    phone: "+34",
  },
  {
    value: "estados_unidos",
    label: "Estados Unidos",
    image: "https://storage.googleapis.com/educabot-aec-cdn-1/tich/flags/united-states.svg",
    code: "US",
    phone: "+1",
  },
  {
    value: "guatemala",
    label: "Guatemala",
    image: "https://storage.googleapis.com/educabot-aec-cdn-1/tich/flags/guatemala.svg",
    code: "GT",
    phone: "+502",
  },
  {
    value: "honduras",
    label: "Honduras",
    image: "https://storage.googleapis.com/educabot-aec-cdn-1/tich/flags/honduras.svg",
    code: "HN",
    phone: "+504",
  },
  {
    value: "mexico",
    label: "México",
    image: "https://storage.googleapis.com/educabot-aec-cdn-1/tich/flags/mexico.svg",
    code: "MX",
    phone: "+52",
  },
  {
    value: "nicaragua",
    label: "Nicaragua",
    image: "https://storage.googleapis.com/educabot-aec-cdn-1/tich/flags/nicaragua.svg",
    code: "NI",
    phone: "+505",
  },
  {
    value: "panama",
    label: "Panamá",
    image: "https://storage.googleapis.com/educabot-aec-cdn-1/tich/flags/panama.svg",
    code: "PA",
    phone: "+507",
  },
  {
    value: "paraguay",
    label: "Paraguay",
    image: "https://storage.googleapis.com/educabot-aec-cdn-1/tich/flags/paraguay.svg",
    code: "PY",
    phone: "+595",
  },
  {
    value: "peru",
    label: "Perú",
    image: "https://storage.googleapis.com/educabot-aec-cdn-1/tich/flags/peru.svg",
    code: "PE",
    phone: "+51",
  },
  {
    value: "puerto_rico",
    label: "Puerto Rico",
    image: "https://storage.googleapis.com/educabot-aec-cdn-1/tich/flags/puerto-rico.svg",
    code: "PR",
    phone: "+1",
  },
  {
    value: "republica_dominicana",
    label: "República Dominicana",
    image: "https://storage.googleapis.com/educabot-aec-cdn-1/tich/flags/republica-dominicana.svg",
    code: "DO",
    phone: "+1",
  },
  {
    value: "uruguay",
    label: "Uruguay",
    image: "https://storage.googleapis.com/educabot-aec-cdn-1/tich/flags/uruguay.svg",
    code: "UY",
    phone: "+598",
  },
  {
    value: "venezuela",
    label: "Venezuela",
    image: "https://storage.googleapis.com/educabot-aec-cdn-1/tich/flags/venezuela.svg",
    code: "VE",
    phone: "+58",
  },
]

export default countries
