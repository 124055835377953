import { Button } from "@/components/ui/button"
import { DialogDescription, DialogFooter, DialogTitle } from "@/components/ui/dialog"
import useEndFreeTrialModal from "../../stores/use-end-free-trial"
import usePlanModal from "../../stores/use-plan-modal"
import useBugSnag from "@/app/hooks/useBugSnag"
import useUniquePayment from "../../queries/use-unique-payment"
import { useUser } from "@/modules/user/stores"
import { toast } from "sonner"
import mixpanelClient from "@/services/mixpanel"

export default function EndTrialPresentationStep() {
  const { onToggle } = useEndFreeTrialModal()
  const { setOpen } = usePlanModal()
  const { notify } = useBugSnag()
  const { user } = useUser()
  const { mutateAsync, isPending } = useUniquePayment()

  async function handleCheckout() {
    try {
      const { data } = await mutateAsync({ type: user.country === "argentina" ? "MP" : "STRIPE" })
      if (!data.initPoint) {
        toast.error("No se pudo redireccionar al checkout de pagos", {
          description: "Por favor intentalo más tarde",
        })
      }
      localStorage.setItem("isUniquePayment", "true")
      window.open(data.initPoint, "_self")
    } catch (err) {
      notify(err)
    }
  }

  function handleExplorePlans() {
    mixpanelClient.payments.explorePremium({
      userId: user.id,
      name: user.name,
      email: user.email,
      country: user.country,
      actualPlan: user.activePlan.name,
    })
    onToggle()
    setOpen()
  }

  return (
    <section className="my-2 flex w-full flex-col gap-4">
      <article className="space-y-2">
        <DialogDescription className="text-center text-base font-bold text-black/60">
          Tu prueba gratuita finalizó
        </DialogDescription>
        <DialogTitle className="text-center text-2xl font-bold text-black/85">
          Desbloqueá tu éxito académico por menos de lo que vale un café.{" "}
        </DialogTitle>
      </article>
      <article className="h-min space-y-2">
        <img
          src="https://storage.googleapis.com/educabot-aec-cdn-1/tich/images/end-trial-modal/end-trial-presentation.png"
          alt="Presentation"
          style={{
            width: "350px",
            height: "350px",
            margin: "0 auto",
            objectFit: "contain",
          }}
        />
        <h2 className="text-center text-base text-black/85">
          Accedé a TichAI con un{" "}
          <strong>pago único de {user.country === "argentina" ? "$990" : "$1 USD"}</strong> y
          realizá hasta <strong>5 actividades por día.</strong>
        </h2>
      </article>
      <DialogFooter className="mx-auto w-full max-w-2xl flex-col gap-4">
        <Button disabled={isPending} className="w-full" onClick={handleCheckout}>
          Empezar por {user.country === "argentina" ? "$990" : "$1 USD"}
        </Button>
        <Button
          disabled={isPending}
          className="w-full bg-primary-50 text-primary"
          onClick={handleExplorePlans}
        >
          Explorar Premium
        </Button>
      </DialogFooter>
    </section>
  )
}
