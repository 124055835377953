import { Button } from "@/components/ui/button"
import usePresentationStepper from "../../stores/use-presentation"
import Stepper from "./stepper"

export default function PresentationStepOne() {
  const { step, setStep } = usePresentationStepper()

  return (
    <section className="mx-auto max-w-7xl">
      {/* DESKTOP */}
      <article className="hidden px-20 lg:block">
        <Stepper />
        <section className="flex h-[80vh] items-center gap-16">
          <section className="flex flex-1 flex-col gap-3">
            <h2 className="text-base font-bold text-black/60">
              Un examen es como una carrera de velocidad
            </h2>
            <h1 className="text-2xl font-bold text-black/85">
              Tenés que dar lo mejor de vos en poco tiempo
            </h1>
            <Button className="mt-12 w-fit" onClick={() => setStep(step + 1)}>
              Siguiente
            </Button>
          </section>
          <section className="flex-1">
            <img
              src="https://storage.googleapis.com/educabot-aec-cdn-1/tich/images/sign-in-presentation/presentation-1.png"
              alt="Imagen de representación"
              className="object-cover"
            />
          </section>
        </section>
      </article>
      {/* MOBILE */}
      <article className="space-y-2 lg:hidden">
        <h2 className="text-center text-base font-bold text-black/85">
          Un examen es como una carrera de velocidad
        </h2>
        <h1 className="mx-auto w-[20ch] text-center text-2xl font-bold text-black/85">
          Tenés que dar lo mejor de vos en poco tiempo
        </h1>
        <img
          src="https://storage.googleapis.com/educabot-aec-cdn-1/tich/images/sign-in-presentation/presentation-1.png"
          alt="Imagen de representación"
        />
      </article>
      <article className="mt-14 flex flex-col items-center space-y-6 px-2 pb-14 lg:hidden">
        <Stepper />
        <Button className="w-full" onClick={() => setStep(step + 1)}>
          Siguiente
        </Button>
      </article>
    </section>
  )
}
