import { useUser } from "../stores"
import useUserPlan from "./use-user-plan"

const useCanContinueLearning = () => {
  const { user } = useUser()
  const { isPro } = useUserPlan()
  const { lives } = user
  const canContinueLearning = isPro || lives > 0
  return canContinueLearning
}

export default useCanContinueLearning
