import { useState } from "react"
import { Button } from "@/components/ui/button"
import EmailLoginForm from "@/modules/login/components/email-form"
import GoogleLoginButton from "@/modules/login/components/google-login-button"
import useGoogleAuthUrl from "@/modules/login/hooks/use-auth-url"
import isProd from "@/utils/is-prod"
import usePresentationStepper from "@/modules/login/stores/use-presentation"
import { ChevronLeft } from "lucide-react"
import { cn } from "@/lib/utils"
import PresentationStepOne from "@/modules/login/components/presentation/step-one"
import PresentationStepTwo from "@/modules/login/components/presentation/step-two"
import PresentationStepThree from "@/modules/login/components/presentation/step-three"

export default function LoginPage() {
  const [showEmailLogin, setShowEmailLogin] = useState(false)
  const { step, setStep } = usePresentationStepper()

  const url = useGoogleAuthUrl({
    clientId: import.meta.env.VITE_GOOGLE_AUTH_CLIENT_ID,
    redirectUri: import.meta.env.VITE_GOOGLE_AUTH_REDIRECT_URI,
  })

  const handleGoogleLogin = () => {
    window.location.href = url
  }

  function handleBackStep() {
    setStep(step - 1)
  }

  function renderStep() {
    switch (step) {
      case 1:
        return <PresentationStepOne />
      case 2:
        return <PresentationStepTwo />
      case 3:
        return <PresentationStepThree />
      case 4:
        return (
          <section className="mx-auto flex h-full max-w-5xl flex-col justify-center lg:flex-row-reverse lg:items-center lg:justify-center lg:gap-16">
            {!showEmailLogin ? (
              <>
                <img
                  src="https://storage.googleapis.com/educabot-aec-cdn-1/tich/images/sign-in-image.png"
                  className="max-h-[400px] object-contain"
                />
                <section className="flex flex-col px-2">
                  <h1 className="mb-16 text-center text-2xl font-bold text-black/85">
                    Sumate a miles de estudiantes que aprueban sus exámenes junto a TichAI
                  </h1>
                  <GoogleLoginButton onClick={handleGoogleLogin} />
                  {!isProd && (
                    <div className="text-center">
                      <Button
                        variant="link"
                        className="text-primary"
                        onClick={() => setShowEmailLogin(true)}
                      >
                        Continuar con Email
                      </Button>
                    </div>
                  )}
                  <p className="my-4 text-center text-sm font-normal text-black/65 lg:hidden">
                    Para una mejor experiencia, asegurate de continuar en tu navegador de
                    preferencia
                  </p>
                </section>
              </>
            ) : (
              <section className="w-full max-w-xl px-4">
                <Button
                  variant="link"
                  className="-ml-4 mb-4"
                  onClick={() => setShowEmailLogin(false)}
                >
                  Volver
                </Button>
                <EmailLoginForm />
              </section>
            )}
          </section>
        )
      default:
        return <PresentationStepOne />
    }
  }

  return (
    <section className="min-h-screen w-full lg:overflow-hidden">
      <header
        className={cn(
          "flex w-full justify-end p-5",
          step === 2 || step === 3 ? "justify-between" : "",
        )}
      >
        <Button variant="ghost" size="icon" onClick={handleBackStep}>
          {step === 2 || step === 3 ? <ChevronLeft className="!h-6 !w-6 text-black/65" /> : null}
        </Button>
        <img
          width={148}
          height={22}
          src="https://storage.googleapis.com/educabot-aec-cdn-1/tich/images/tich_logo.png"
          className="object-contain"
        />
      </header>
      {renderStep()}
    </section>
  )
}
