import { useUser } from "@/modules/user/stores"
import { PLAN_TYPE } from "@/services/api/users/info/get"

export default function useUserPlan() {
  const { user } = useUser()
  const { activePlan } = user
  const isFree = [PLAN_TYPE.FREE, PLAN_TYPE.BASIC].includes(activePlan.type)
  const isPro = !isFree

  return {
    isFree,
    isPro,
    ...activePlan,
  }
}
