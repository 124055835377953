import { api } from "@/services/api"
import { useQuery } from "@tanstack/react-query"

export const useGetAvailablePlans = () => {
  const query = useQuery({
    queryKey: ["get-available-plans"],
    queryFn: async () => api.plans.getAll(),
    staleTime: 1000 * 60 * 5, // 5 minutes
  })
  return query
}
