import { type StateCreator } from "zustand"
import { create } from "@/app/state"

interface EndFreeTrialModalState {
  isOpen: boolean
  step: number
  setStep: (step: number) => void
  onToggle: () => void
  reset: () => void
}

const state: StateCreator<EndFreeTrialModalState> = (set, get) => ({
  isOpen: false,
  step: 0,
  onToggle: () => set({ isOpen: !get().isOpen }),
  setStep: (step) => set({ step }),
  reset: () => set({ isOpen: false, step: 0 }),
})

const useEndFreeTrialModal = create<EndFreeTrialModalState>()(state)

export default useEndFreeTrialModal
