import { client } from "@/config/client"

export interface SendCancellationFeedbackBody {
  cancellationId: string
  feedback: string
}

export default function sendCancellationFeedback(body: SendCancellationFeedbackBody) {
  return client.put<void, SendCancellationFeedbackBody>("/v1/payments/cancel/feedback", body)
}
