import { client } from "@/config/client"
import type { Document } from "../../documents/get"

export enum STUDENT_ROLE {
  SELF_TAUGHT = "SELF_TAUGHT",
  UNIVERSITY = "UNIVERSITY",
  TERTIARY = "TERTIARY",
  SECONDARY = "SECONDARY",
}

export enum PLAN_TYPE {
  FREE = "FREE",
  BASIC = "BASIC",
  MONTHLY_INDIVIDUAL = "MONTHLY_INDIVIDUAL",
  YEARLY_INDIVIDUAL = "YEARLY_INDIVIDUAL",
  YEARLY_FAMILY = "YEARLY_FAMILY",
  GIFT = "GIFT",
}

export enum PLAN_DURATION {
  ONE_MONTH = "ONE_MONTH",
  ONE_YEAR = "ONE_YEAR",
  INFINITY = "INFINITY",
}

export type BasePlan = {
  id: string
  name: string
}

export type FreePlan = BasePlan & {
  duration: PLAN_DURATION.INFINITY
  type: PLAN_TYPE.FREE
}

export type BasicPlan = BasePlan & {
  duration: PLAN_DURATION.INFINITY
  type: PLAN_TYPE.BASIC
}

export type MonthlyIndividualPlan = BasePlan & {
  duration: PLAN_DURATION.ONE_MONTH
  type: PLAN_TYPE.MONTHLY_INDIVIDUAL
}

export type YearlyIndividualPlan = BasePlan & {
  duration: PLAN_DURATION.ONE_YEAR
  type: PLAN_TYPE.YEARLY_INDIVIDUAL
}

export type YearlyFamilyPlan = BasePlan & {
  duration: PLAN_DURATION.ONE_YEAR
  type: PLAN_TYPE.YEARLY_FAMILY
}

export type GiftedPlan = BasePlan & {
  duration: PLAN_DURATION.INFINITY
  type: PLAN_TYPE.GIFT
}

export type ActivePlan =
  | FreePlan
  | BasicPlan
  | MonthlyIndividualPlan
  | YearlyIndividualPlan
  | YearlyFamilyPlan
  | GiftedPlan

export type LearningSession = {
  id: string
  name: string
  score?: number
  index?: number
  learningPathSubthemeId: string
  learningPathId: string
  createdAt: Date
  updatedAt: Date
}

export type UserPoint = {
  id: number
  points: number
}

export type UserInfo = {
  id: string
  name: string
  email: string
  country: string
  role: "STUDENT" | "TEACHER" | "ADMIN"
  avatar: string
  studentRole: STUDENT_ROLE
  lives: number
  learningSessions: LearningSession[]
  documents: Document[]
  userPoints: UserPoint[]
  refillLivesAt: string
  diagnoseUses: number
  university?: string | null
  career?: string | null
  movie?: string | null
  interests?: string | null
  activePlan: ActivePlan
}

export type GetUserInfoResponse = {
  user: UserInfo
}

export function get() {
  return client.get<GetUserInfoResponse>("/v1/users/info")
}
