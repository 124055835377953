import useBugSnag from "@/app/hooks/useBugSnag"
import { Button } from "@/components/ui/button"
import { Form, FormControl, FormField, FormItem, FormMessage } from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { useProcessMercadoPago } from "@/modules/plans/queries"
import usePlanModal from "@/modules/plans/stores/use-plan-modal"
import { mpEmailSchema, type MpEmailSchema } from "@/modules/plans/validations/mp-email"
import { zodResolver } from "@hookform/resolvers/zod"
import { X } from "lucide-react"
import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "sonner"

export default function MercadoPageEmailPage() {
  const router = useNavigate()
  const { setOpen } = usePlanModal()
  const { id } = useParams()
  const { handleMercadoPagoProcess, processMP } = useProcessMercadoPago()
  const { notify } = useBugSnag()

  const form = useForm<MpEmailSchema>({
    resolver: zodResolver(mpEmailSchema),
  })

  function onClose() {
    router(-1)
    setOpen()
  }

  async function onSubmit(data: MpEmailSchema) {
    if (!id) return
    try {
      await handleMercadoPagoProcess(id, data.email)
    } catch (error) {
      toast.error("Ha ocurrido un error al comprar el paquete", {
        description: "Por favor intentalo más tarde",
      })
      notify(error)
    }
  }

  useEffect(() => {
    if (!id) {
      router(-1)
      setOpen()
    }
  }, [id])

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex h-dvh w-full flex-col bg-neutral-50 px-4"
      >
        <header className="flex w-full items-center justify-end py-2.5 pr-1">
          <Button
            disabled={processMP.isPending}
            type="button"
            variant="ghost"
            size="icon"
            onClick={onClose}
          >
            <X className="!size-8 text-black/60" />
          </Button>
        </header>

        <section className="flex flex-1 flex-col items-center justify-center">
          <section className="mx-auto flex w-full max-w-5xl flex-col items-center">
            <img
              src="https://storage.googleapis.com/educabot-aec-cdn-1/tich/images/mercado-pago-icon.png"
              alt="mercado pago icon"
              className="mb-6"
            />
            <h1 className="text-center text-2xl font-bold text-black/85">
              Ingresá el e-mail de tu cuenta en Mercado Pago
            </h1>
            <p className="mt-2 text-center text-lg font-normal text-black/85">
              Necesitamos tu e-mail para continuar con el pago.
            </p>
            <article className="mt-6 w-full max-w-[400px]">
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input placeholder="Ingresá tu e-mail" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </article>
          </section>
        </section>
        <Button
          type="submit"
          size="lg"
          className="mx-auto mb-10 w-full max-w-[400px]"
          disabled={processMP.isPending}
        >
          Continuar
        </Button>
      </form>
    </Form>
  )
}
