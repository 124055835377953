import { client } from "@/config/client"

export interface CancelSubscriptionBody {
  reasonIds: string[]
}

export interface CancelSubscriptionResponse {
  billingPeriodEnd: string
  cancellationId: string
}

export default function cancelSubscription(body: CancelSubscriptionBody) {
  return client.put<CancelSubscriptionResponse, CancelSubscriptionBody>("/v1/payments/cancel", body)
}
