import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog"
import { toast } from "sonner"
import useDeleteLpById from "@/modules/app/queries/use-delete-lp-by-id"
import useDeleteSubjectModal from "../../stores/use-delete-subject"

interface DeleteLearningPathDialogProps {
  onDelete: () => void
}

export default function DeleteLearningPathDialog({ onDelete }: DeleteLearningPathDialogProps) {
  const { isOpen, closeModal, selectedLpId, selectedLpName } = useDeleteSubjectModal()
  const { mutateAsync } = useDeleteLpById(selectedLpId)

  const handleOnClickDelete = async () => {
    const result = await mutateAsync()
    if (result) {
      onDelete()
      closeModal()
      toast.success("Materia eliminada")
      return
    }

    toast.error("No se pudo eliminar la materia")
  }

  return (
    <AlertDialog open={isOpen} onOpenChange={closeModal}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Eliminar materia</AlertDialogTitle>
          <AlertDialogDescription>
            ¿Estás seguro que deseas eliminar{" "}
            <span className="font-bold text-destructive">{selectedLpName}</span>?
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={closeModal}>Cancelar</AlertDialogCancel>
          <AlertDialogAction onClick={handleOnClickDelete}>Eliminar</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
