import { create } from "@/app/state"
import type { LearningPath } from "@/services/api/learning-paths/get"
import type { StateCreator } from "zustand"

interface RenameModalState {
  isOpen: boolean
  selectedLpId: string
  selectedLpName: string
  openModal: (lp: LearningPath) => void
  closeModal: () => void
}

const state: StateCreator<RenameModalState> = (set) => ({
  isOpen: false,
  selectedLpId: "",
  selectedLpName: "",
  openModal: (lp) => set({ isOpen: true, selectedLpId: lp.id, selectedLpName: lp.name }),
  closeModal: () => set({ isOpen: false }),
})

const useRenameSubjectModal = create<RenameModalState>()(state)

export default useRenameSubjectModal
