import { Loader } from "@/components/loader"
import useGetUser from "@/modules/user/queries/use-get-user"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "sonner"

const SubscriptionCallback = () => {
  const navigate = useNavigate()
  const { refetch } = useGetUser()

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const status = params.get("status")

    if (status === "approved") {
      toast.success("Pago recibido con éxito", { position: "top-center" })

      sessionStorage.setItem("pay_approved", "true")
    } else if (status === "pending") {
      toast.info("Pago en revisión. Le notificaremos cuando se haya recibido.", {
        position: "top-center",
      })
    } else if (status === "failure") {
      toast.error("Algo salió mal, intenta de nuevo más tarde", { position: "top-center" })
    } else {
      toast.error("Estado desconocido. Por favor, contacta al soporte.", { position: "top-center" })
    }

    // Redirect dashboard after 5 seconds
    const timeout = setTimeout(() => {
      refetch()
      navigate("/dashboard")
    }, 5000)

    // Clear timeout when component unmounts
    return () => clearTimeout(timeout)
  }, [navigate])

  return (
    <div className="flex h-dvh w-full items-center justify-center">
      <Loader />
    </div>
  )
}

export default SubscriptionCallback
