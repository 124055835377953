import { Card } from "@/components/ui/card"
import { MoreVertical } from "lucide-react"
import { useNavigate } from "react-router-dom"
import type { LearningPath } from "@/services/api/learning-paths/get"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Button } from "@/components/ui/button"
import useDeleteSubjectModal from "../stores/use-delete-subject"
import useRenameSubjectModal from "../stores/use-rename-subject"

interface LearningPathProps {
  learningPath: LearningPath
}

export default function LearningPath({ learningPath }: LearningPathProps) {
  const navigate = useNavigate()
  const deleteLp = useDeleteSubjectModal()
  const renameLp = useRenameSubjectModal()

  const handleView = () => {
    navigate(`/learning-paths/${learningPath.id}`)
  }

  const handleEdit = () => {
    navigate(`/learning-paths/edit/${learningPath.id}`)
  }

  const handleDelete = () => {
    deleteLp.openModal(learningPath)
  }

  const handleRename = () => {
    renameLp.openModal(learningPath)
  }

  return (
    <Card className="flex items-center justify-between rounded-lg border border-solid px-6 py-5">
      <p className="text-md font-medium">{learningPath.name}</p>
      <div className="flex h-full gap-1">
        <Button variant="secondary" onClick={handleView}>
          Ver Detalles
        </Button>
        <DropdownMenu>
          <DropdownMenuTrigger>
            <Button variant="ghost">
              <MoreVertical className="h-5 w-5" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem onClick={handleRename}>Renombrar</DropdownMenuItem>
            <DropdownMenuItem onClick={handleEdit}>Editar</DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem
              onClick={handleDelete}
              className="text-red-500 focus:bg-red-100 focus:text-red-500"
            >
              Eliminar
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </Card>
  )
}
