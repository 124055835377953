import { useForm } from "react-hook-form"
import type { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { toast } from "sonner"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { Dialog, DialogHeader, DialogContent, DialogTitle } from "@/components/ui/dialog"
import { Button } from "@/components/button"
import { renameSubjectSchema } from "../../validations/rename-lp"
import useUpdateLp from "@/modules/app/queries/use-update-lp"
import useBugSnag from "@/app/hooks/useBugSnag"
import useRenameSubjectModal from "../../stores/use-rename-subject"
import { useEffect } from "react"
import useGetLps from "@/modules/app/queries/use-get-lps"
import { useLearningPaths } from "@/modules/app/stores/use-lp-store"

type FormValues = z.infer<typeof renameSubjectSchema>

export default function RenameLearningPathModal() {
  const { isOpen, closeModal, selectedLpId, selectedLpName } = useRenameSubjectModal()
  const { selectedLearningPath, setSelectedLearningPath } = useLearningPaths()
  const { mutateAsync, isPending } = useUpdateLp(selectedLpId)
  const { notify } = useBugSnag()
  const { refetch } = useGetLps()

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<FormValues>({
    resolver: zodResolver(renameSubjectSchema),
    defaultValues: {
      name: selectedLpName,
    },
  })

  const onSubmit = async (data: FormValues) => {
    try {
      await mutateAsync({
        name: data.name,
      })
      toast.success("Materia renombrada exitosamente")
      handleClose()
      if (selectedLearningPath.id === selectedLpId) {
        setSelectedLearningPath({ ...selectedLearningPath, name: data.name })
      }
      refetch()
    } catch (err) {
      notify(err)
      toast.error("Ocurrió un error al renombrar la materia. Por favor intenta de nuevo.")
    }
  }

  const handleClose = () => {
    reset({
      name: selectedLpName,
    })
    closeModal()
  }

  useEffect(() => {
    setValue("name", selectedLpName)
  }, [selectedLpId, selectedLpName])

  return (
    <Dialog open={isOpen} onOpenChange={handleClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Renombrar materia</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="name">Nombre de la materia</Label>
            <Input id="name" placeholder="Ej: Macroeconomía básica" {...register("name")} />
            {errors.name && <p className="text-sm text-red-500">{errors.name.message}</p>}
          </div>
          <div className="flex justify-end gap-3">
            <Button type="button" variant="outline" onClick={handleClose} disabled={isPending}>
              Cancelar
            </Button>
            <Button type="submit" disabled={isPending} loading={isPending}>
              Renombrar
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  )
}
