import { api } from "@/services/api"
import type { UniquePayRequest } from "@/services/api/payments/unique-pay"
import { useMutation } from "@tanstack/react-query"

const useUniquePayment = () => {
  const mutation = useMutation({
    mutationKey: ["unique-payment"],
    mutationFn: async (payload: UniquePayRequest) => api.payments.uniquePayment(payload),
  })

  return mutation
}

export default useUniquePayment
