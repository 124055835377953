import challenges from "./challenges"
import { user } from "./users"
import { admin } from "./admin"
import { documents } from "./documents"
import { learnignPaths } from "./learning-paths"
import { stripe } from "./stripe"
import { mindmaps } from "./mindmaps"
import diagnoses from "./diagnoses"
import { plans } from "./plans"
import { payments } from "./payments"

export const api = {
  admin,
  challenges,
  diagnoses,
  documents,
  learnignPaths,
  mindmaps,
  payments,
  plans,
  stripe,
  user,
}
