import { api } from "@/services/api"
import type { UpdateProfileData } from "@/services/api/users/update-profile"
import { useMutation, useQueryClient } from "@tanstack/react-query"

type Payload = {
  data: UpdateProfileData
}

const useSaveContactPhone = () => {
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationKey: ["save-contact-phone"],
    mutationFn: async (payload: Payload) => api.user.updateProfile(payload.data),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["get-user"] })
    },
  })
  return mutation
}
export default useSaveContactPhone
