import type { LearningPath } from "@/services/api/learning-paths/get"
import LearningPathCard from "./learning-path-card"
import DeleteLearningPathDialog from "./delete/delete-learning-path-modal"
import RenameLearningPathModal from "./edit/rename-learning-path-modal"

interface LearningPathsProps {
  learningPaths: LearningPath[]
  onDelete: () => void
}

export default function LearningPaths({ learningPaths, onDelete }: LearningPathsProps) {
  return (
    <div className="flex w-full flex-col gap-4">
      {!learningPaths?.length ? (
        <div className="flex h-32 w-full flex-col items-center justify-center gap-2">
          <p className="text-md w-full text-center font-light text-primary sm:w-2/5">
            No tenés exámenes creados. Empezá ahora.
          </p>
        </div>
      ) : (
        learningPaths.map((learningPath) => (
          <LearningPathCard key={learningPath.id} learningPath={learningPath} />
        ))
      )}
      <DeleteLearningPathDialog onDelete={onDelete} />
      <RenameLearningPathModal />
    </div>
  )
}
