import {
  PLAN_DURATION,
  PLAN_TYPE,
  STUDENT_ROLE,
  type UserInfo,
} from "@/services/api/users/info/get"
import { type StateCreator } from "zustand"
import { create } from "@/app/state"
import { persist } from "zustand/middleware"

interface UserState {
  user: UserInfo
  setUser: (user: UserInfo) => void
}

const state: StateCreator<UserState> = (set) => ({
  user: {
    id: "",
    name: "",
    email: "",
    country: "",
    role: "STUDENT",
    studentRole: STUDENT_ROLE.SECONDARY,
    avatar: "",
    lives: 0,
    learningSessions: [],
    documents: [],
    userPoints: [],
    refillLivesAt: "",
    diagnoseUses: 0,
    activePlan: {
      id: "",
      name: "",
      duration: PLAN_DURATION.INFINITY,
      type: PLAN_TYPE.FREE,
    },
  },
  setUser: (user: UserInfo) => set({ user }),
})

export const useUser = create<UserState>()(
  persist(state, {
    name: "user_info",
  }),
)
