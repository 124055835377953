import { z } from "zod"

export const endTrialFormSchema = z.object({
  phone: z
    .string({
      required_error: "El número de teléfono es requerido",
    })
    .min(1, "El número de teléfono es requerido")
    .regex(/^\+?[0-9]{10,15}$/, "Ingrese un número de teléfono válido"),
})

export type EndTrialFormValues = z.infer<typeof endTrialFormSchema>
