import mixpanel from "mixpanel-browser"
import { LOGIN_EVENTS } from "."

interface CompletedLoginProps {
  userId: string
  name: string
  email: string
  lastLogin: string
}

export function completed(props: CompletedLoginProps) {
  const { userId, name, email, lastLogin, ...rest } = props
  mixpanel.identify(userId)
  mixpanel.people.set({ $name: name, $email: email, lastLogin })
  mixpanel.track(LOGIN_EVENTS.COMPLETED, { userId, ...rest })
}
