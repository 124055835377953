import { create } from "@/app/state"
import type { LearningPath } from "@/services/api/learning-paths/get"
import type { StateCreator } from "zustand"

interface DeleteModalState {
  isOpen: boolean
  selectedLpId: string
  selectedLpName: string
  openModal: (lp: LearningPath) => void
  closeModal: () => void
}

const state: StateCreator<DeleteModalState> = (set) => ({
  isOpen: false,
  selectedLpId: "",
  selectedLpName: "",
  openModal: (lp) => set({ isOpen: true, selectedLpId: lp.id, selectedLpName: lp.name }),
  closeModal: () => set({ isOpen: false, selectedLpId: "", selectedLpName: "" }),
})

const useDeleteSubjectModal = create<DeleteModalState>()(state)

export default useDeleteSubjectModal
