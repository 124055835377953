import mixpanel from "mixpanel-browser"
import { CHALLENGES_EVENTS } from "."

interface NextLevelProps {
  subject: string
}

export function nextLevel(props: NextLevelProps) {
  mixpanel.track(CHALLENGES_EVENTS.NEXT_LEVEL, props)
}
