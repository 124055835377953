import { completeLevel } from "./complete-level"
import { nextLevel } from "./next-level"
import { repeatLevel } from "./repeat-level"
import { startLevel } from "./start-level"

export enum CHALLENGES_EVENTS {
  START_LEVEL = "User starts a level inside a challenge",
  COMPLETE_LEVEL = "User completes a level inside a challenge",
  REPEAT_LEVEL = "After complete a challenge User repeats a level",
  NEXT_LEVEL = "User goes to the next level inside a challenge",
}

export const challenges = {
  startLevel,
  completeLevel,
  repeatLevel,
  nextLevel,
}
