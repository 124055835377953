import Login from "@/app/routes/login"
import Main from "@/app/routes/main"
import Onboarding from "@/app/routes/onboarding"
import GoogleAuth from "@/pages/auth/google-auth"
import MercadoPageEmailPage from "@/pages/plans/mp-email"
import SubscriptionCallback from "@/pages/subscription/subscription-callback"
import { Navigate, createBrowserRouter } from "react-router-dom"

const router = createBrowserRouter([
  Main,
  Login,
  Onboarding,
  {
    path: "/auth/google/callback",
    element: <GoogleAuth />,
  },
  {
    path: "/subscription/callback",
    element: <SubscriptionCallback />,
  },
  {
    path: "/plans/:id/mp-email",
    element: <MercadoPageEmailPage />,
  },
  {
    path: "*",
    element: <Navigate to="/login" replace />,
  },
])

export default router
