import { Dialog, DialogContent } from "@/components/ui/dialog"
import useEndFreeTrialModal from "../../stores/use-end-free-trial"
import EndTrialPresentationStep from "./presentation-step"
import EndTrialFormStep from "./form-step"

export default function EndFreeTrialModal() {
  const { isOpen, step, onToggle } = useEndFreeTrialModal()

  return (
    <Dialog open={isOpen} onOpenChange={onToggle}>
      <DialogContent className="flex w-[90%] max-w-[90%] rounded-md">
        {step === 0 && <EndTrialPresentationStep />}
        {step === 1 && <EndTrialFormStep />}
      </DialogContent>
    </Dialog>
  )
}
