import Stepper from "./stepper"
import { Button } from "@/components/ui/button"
import usePresentationStepper from "../../stores/use-presentation"

export default function PresentationStepTwo() {
  const { step, setStep } = usePresentationStepper()

  return (
    <section className="mx-auto max-w-7xl">
      {/* DESKTOP */}
      <article className="hidden px-20 lg:block">
        <Stepper />
        <section className="flex h-[80vh] items-center gap-16">
          <section className="flex flex-1 flex-col gap-3">
            <h2 className="text-base font-bold text-black/85">
              Leer y releer es como salir a caminar
            </h2>
            <div>
              <h2 className="text-base font-normal text-black/85">
                Ayuda, pero no te prepara de verdad.
              </h2>
              <h2 className="text-base font-normal text-black/85">
                Al no exigirte, es fácil distraerte.
              </h2>
            </div>
            <h1 className="mt-4 w-[25ch] text-2xl font-bold text-black/85">
              <span className="text-primary">Preparate para ganar</span> usando técnicas de estudio
              activo
            </h1>
            <Button className="mt-12 w-fit" onClick={() => setStep(step + 1)}>
              Siguiente
            </Button>
          </section>
          <section className="flex-1">
            <img
              src="https://storage.googleapis.com/educabot-aec-cdn-1/tich/images/sign-in-presentation/presentation-2.png"
              alt="Imagen de representación"
              className="object-cover"
            />
          </section>
        </section>
      </article>
      {/* MOBILE */}
      <article className="space-y-2 lg:hidden">
        <h2 className="text-center text-base font-bold text-black/85">
          Leer y releer es como salir a caminar
        </h2>
        <h1 className="mx-auto w-[30ch] text-center text-base font-normal text-black/85">
          Ayuda, pero no te prepara de verdad. Al no exigirte, es fácil distraerte.
        </h1>
        <img
          src="https://storage.googleapis.com/educabot-aec-cdn-1/tich/images/sign-in-presentation/presentation-2.png"
          alt="Imagen de representación"
        />
        <h1 className="mx-auto w-[25ch] text-center text-2xl font-bold text-black/85">
          <span className="text-primary">Preparate para ganar</span> usando técnicas de estudio
          activo
        </h1>
      </article>
      <article className="mt-14 flex flex-col items-center space-y-6 px-2 pb-14 lg:hidden">
        <Stepper />
        <Button className="w-full" onClick={() => setStep(step + 1)}>
          Siguiente
        </Button>
      </article>
    </section>
  )
}
