import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert"
import usePlanModal from "@/modules/plans/stores/use-plan-modal"
import { useUser } from "@/modules/user/stores"
import { Info } from "lucide-react"

export default function FreeTrialBanner() {
  const { setOpen } = usePlanModal()
  const { user } = useUser()
  const withoutLives = user.lives <= 0

  return (
    <Alert className="mb-4 w-full rounded-none border-none bg-primary-50 py-6 shadow-none">
      <Info className="mt-2 size-5 !text-primary" />
      <AlertTitle className="text-sm font-bold text-black/85">
        {withoutLives
          ? "Tu prueba gratuita finalizó"
          : "Te damos la bienvenida a tu prueba gratuita"}
      </AlertTitle>
      <AlertDescription className="text-sm font-normal text-black/85">
        {withoutLives ? (
          <>
            Obtené 5 vidas por día con un pago único de{" "}
            {user.country === "argentina" ? "$990" : "$1 USD"} o explorá nuestros{" "}
            <span
              onClick={() => setOpen()}
              className="cursor-pointer font-medium text-primary underline"
            >
              planes Premium limitados.
            </span>
          </>
        ) : (
          <>
            Tenés 5 vidas para probar TichAI 100% gratis. Renová 5 vidas cada 24hs con un pago único
            de {user.country === "argentina" ? "$990" : "$1 USD"} o explorá nuestros{" "}
            <span
              onClick={() => setOpen()}
              className="cursor-pointer font-medium text-primary underline"
            >
              planes Premium limitados.
            </span>
          </>
        )}
      </AlertDescription>
    </Alert>
  )
}
