import mixpanel from "mixpanel-browser"
import { FLASHCARDS_EVENTS } from "."

interface MoreFlashcardProps {
  userPlan: string
  userName: string
  userEmail: string
}

export function moreFlashcards(props: MoreFlashcardProps) {
  return mixpanel.track(FLASHCARDS_EVENTS.MORE_FLASHCARDS, {
    ...props,
  })
}
