import UserMenu from "@/modules/app/components/user-menu"
import LearningPathsSelector from "@/modules/app/components/learning-path-selector"
import MobileLearningPathMenu from "@/modules/app/components/mobile-lp-menu"
import FreeTrialBanner from "@/modules/app/components/free-trial-banner"
import { PLAN_TYPE } from "@/services/api/users/info/get"
import useUserPlan from "@/modules/user/hooks/use-user-plan"

export default function Header() {
  const { type } = useUserPlan()

  return (
    <header className="flex h-fit w-full flex-col">
      {type === PLAN_TYPE.FREE && <FreeTrialBanner />}
      <section className="flex px-5 pt-10 lg:px-10">
        <div className="hidden w-full items-center md:flex">
          <LearningPathsSelector />
        </div>
        <div className="flex w-full items-center md:hidden">
          <MobileLearningPathMenu />
        </div>
        <div className="flex w-full justify-end gap-12">
          <span className="flex items-center justify-between gap-4">
            <UserMenu />
          </span>
        </div>
      </section>
    </header>
  )
}
