import { cn } from "@/lib/utils"
import usePresentationStepper from "../../stores/use-presentation"

export default function Stepper() {
  const { step } = usePresentationStepper()

  return (
    <div className="flex items-center gap-2">
      <div className={cn("h-3 w-3 rounded-full bg-black/30", step === 1 && "bg-black/60")} />
      <div className={cn("h-3 w-3 rounded-full bg-black/30", step === 2 && "bg-black/60")} />
      <div className={cn("h-3 w-3 rounded-full bg-black/30", step === 3 && "bg-black/60")} />
    </div>
  )
}
