import SidePanel from "@/modules/app/components/side-panel"
import Header from "./header"
import Body from "./body"
import { useEffect } from "react"
import { MobileNavbar } from "@/modules/app/components/mobile-navbar"
import { useUser } from "@/modules/user/stores"
import useBugSnag from "@/app/hooks/useBugSnag"
import useClarityIdentify from "@/app/hooks/useClarityIdentify"
import useLayout from "@/app/hooks/useLayout"
import { useMediaQuery } from "usehooks-ts"
import PlanDialog from "@/modules/plans/components/plan-dialog"
import PlanDrawer from "@/modules/plans/components/plan-drawer"
import EndFreeTrialModal from "@/modules/plans/components/end-free-trial/modal"

export default function Main() {
  const { user } = useUser()
  const { showOnlyBody } = useLayout()
  const bug = useBugSnag()
  const clarity = useClarityIdentify()
  const isDesktop = useMediaQuery("(min-width: 768px)")

  useEffect(() => {
    bug.init({
      id: user.id,
      email: user.email,
      name: user.name,
    })

    clarity.init({
      id: user.id,
      friendlyName: user.name,
    })

    clarity.setTag("user_email", user.email)
  }, [user.id])

  if (showOnlyBody) {
    return (
      <div className="size-full overflow-hidden">
        <Body />
      </div>
    )
  }

  return (
    <div className="grid h-full w-full md:grid-cols-[94px,1fr]">
      <div className="hidden h-full w-full md:flex">
        <SidePanel />
      </div>
      <div className="absolute md:hidden">
        <MobileNavbar />
      </div>
      <div className="grid grid-rows-[auto_1fr] overflow-auto bg-gray-50">
        <Header />
        <Body />
      </div>
      {isDesktop ? <PlanDialog children={null} /> : <PlanDrawer children={null} />}
      <EndFreeTrialModal />
    </div>
  )
}
