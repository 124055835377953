import useBugSnag from "@/app/hooks/useBugSnag"
import { api } from "@/services/api"
import type { ProcessCardPaymentRequest } from "@/services/api/payments/process-mp"
import { useMutation } from "@tanstack/react-query"
import { toast } from "sonner"

export const useProcessMercadoPago = () => {
  const { notify } = useBugSnag()
  const mutation = useMutation({
    mutationKey: ["process-mp-payment"],
    mutationFn: (params: ProcessCardPaymentRequest) => api.payments.processMercadoPago(params),
  })

  async function handleMercadoPagoProcess(planId: string, email: string) {
    try {
      const { data } = await mutation.mutateAsync({ planId, email })
      if (!data.initPoint) {
        toast.error("No se pudo obtener la URL de redirección", {
          description: "Por favor intentalo más tarde",
        })
      }
      window.location.href = data.initPoint
    } catch (error) {
      notify(error)
    }
  }

  return { processMP: mutation, handleMercadoPagoProcess }
}
