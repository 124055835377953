import { client } from "@/config/client"

export type ListSubtheme = {
  name: string
  id?: string
}

export type ListTheme = {
  name: string
  id?: string
  subthemes: ListSubtheme[]
}

export interface UpdateData {
  name?: string
  themes?: ListTheme[]
}

export function update(id: string, data: UpdateData) {
  return client.put(`/v1/learning-paths/${id}`, data)
}
