import mixpanel from "mixpanel-browser"
import { FLASHCARDS_EVENTS } from "."

interface CreateFlashcardProps {
  creationDate: string
  userPlan: string
  userName: string
  userEmail: string
}

export function createFlashcard(props: CreateFlashcardProps) {
  return mixpanel.track(FLASHCARDS_EVENTS.CREATE_FLASHCARD, {
    ...props,
  })
}
