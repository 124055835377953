import { z } from "zod"

export const mpEmailSchema = z.object({
  email: z
    .string({
      required_error: "Campo requerido",
    })
    .email({
      message: "E-mail inválido. Asegurate de que tenga el formato correcto",
    }),
})

export type MpEmailSchema = z.infer<typeof mpEmailSchema>
