import { client } from "@/config/client"

export type ProcessCardPaymentRequest = {
  planId: string
  email: string
}

export interface ProcessCardPaymentResponse {
  status: string
  detail: string
  initPoint: string
}

export function processMercadoPago(data: ProcessCardPaymentRequest) {
  return client.post<ProcessCardPaymentResponse, ProcessCardPaymentRequest>(
    `/v1/payments/mercadopago`,
    data,
  )
}
