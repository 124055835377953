import { api } from "@/services/api"
import { useMutation, useQueryClient } from "@tanstack/react-query"

interface UseEditUserVars {
  id: number
  lives: number
  country: string
  gifted: boolean
}

export default function useEditUser() {
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: async ({ id, ...values }: UseEditUserVars) => api.user.update(id, values),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["get-user"] })
    },
  })

  return mutation
}
