import { object, z } from "zod"

export const renameSubjectSchema = object({
  name: z
    .string({
      required_error: "El campo es requerido.",
    })
    .min(2, "El campo debe tener al menos 2 caracteres.")
    .max(180, "El campo no puede tener más de 180 caracteres.")
    .regex(
      /^[a-zA-ZáéíóúÁÉÍÓÚñÑ0-9\s,.\\-]+$/,
      "Solo se permiten letras, números, espacios, comas, guiones y puntos.",
    )
    .transform((val) => val.trim()),
})

export type RenameSubjectSchema = z.infer<typeof renameSubjectSchema>
