import mixpanel from "mixpanel-browser"
import { FLASHCARDS_EVENTS } from "."

interface CompleteFlashcardProps {
  completeDate: string
  userPlan: string
  userName: string
  userEmail: string
}

export function completeFlashcard(props: CompleteFlashcardProps) {
  return mixpanel.track(FLASHCARDS_EVENTS.COMPLETE_FLASHCARD, {
    ...props,
  })
}
