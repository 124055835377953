import { challenges } from "./challenges"
import { flashcards } from "./flashcards"
import { login } from "./login"
import { payments } from "./payments"
import { signUp } from "./sign-up"

export interface MixPanelGlobalProperties {
  platform: string
  pageName: string
  userCountry: string
  userYear: string
  userArea: string
  userRole: string
}

const mixpanelClient = {
  signUp,
  login,
  challenges,
  flashcards,
  payments,
}

export default mixpanelClient
