import { Button } from "@/components/ui/button"
import { DialogDescription, DialogFooter, DialogTitle } from "@/components/ui/dialog"
import useEndFreeTrialModal from "../../stores/use-end-free-trial"
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import countries from "@/constants/countries"
import { ScrollArea } from "@/components/ui/scroll-area"
import { useEffect, useState } from "react"
import { useUser } from "@/modules/user/stores"
import { ChevronDown } from "lucide-react"
import { Input } from "@/components/ui/input"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import type { EndTrialFormValues } from "../../validations/end-trial-phone"
import { endTrialFormSchema } from "../../validations/end-trial-phone"
import { Form, FormControl, FormField, FormItem, FormMessage } from "@/components/ui/form"
import useBugSnag from "@/app/hooks/useBugSnag"
import useSaveContactPhone from "../../queries/use-save-contact-phone"
import { toast } from "sonner"

export default function EndTrialFormStep() {
  const { reset } = useEndFreeTrialModal()
  const { user } = useUser()
  const { notify } = useBugSnag()
  const originCountry = countries.find((country) => country.value === user.country) ?? countries[0]
  const [selectedCountry, setSelectedCountry] = useState(originCountry)
  const { mutateAsync, isPending } = useSaveContactPhone()

  const form = useForm<EndTrialFormValues>({
    resolver: zodResolver(endTrialFormSchema),
    defaultValues: {
      phone: selectedCountry.phone,
    },
  })

  useEffect(() => {
    form.setValue("phone", selectedCountry.phone)
  }, [selectedCountry])

  async function onSubmit(data: EndTrialFormValues) {
    try {
      await mutateAsync({ data })
      toast.success("Hemos registrado correctamente tu número celular")
      reset()
    } catch (error) {
      notify(error)
    }
  }

  return (
    <section className="mx-auto my-2 w-full max-w-2xl">
      <Form {...form}>
        <form className="flex flex-col gap-4" onSubmit={form.handleSubmit(onSubmit)}>
          <article className="space-y-2">
            <DialogDescription className="text-center text-base font-bold text-black/60">
              Gracias por elegirnos 🧡
            </DialogDescription>
            <DialogTitle className="text-center text-2xl font-bold text-black/85">
              Disfrutá de TichAI. Realizá hasta 5 actividades por día.
            </DialogTitle>
          </article>
          <article className="space-y-2">
            <img
              src="https://storage.googleapis.com/educabot-aec-cdn-1/tich/images/end-trial-modal/end-trial-form.png"
              alt="Presentation"
              className="mx-auto h-[350px] object-contain"
            />
            <h2 className="font-medium">
              Podés dejarnos tu celular para sumarte a nuestra comunidad y recibir contenido
              exclusivo.
            </h2>

            <FormField
              control={form.control}
              name="phone"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormControl>
                    <section className="inline-flex w-full items-center gap-2">
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button variant="outline" className="rounded-sm py-6 shadow-sm">
                            <img className="size-7" src={selectedCountry.image} alt="Flag" />
                            <ChevronDown className="!size-6" />
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent className="w-56">
                          <ScrollArea className="h-60">
                            {countries.map((country) => (
                              <DropdownMenuCheckboxItem
                                key={country.code}
                                className="cursor-pointer justify-between gap-2 px-2 py-3 hover:bg-gray-100"
                                onClick={() => setSelectedCountry(country)}
                              >
                                <section className="inline-flex gap-2">
                                  <img
                                    className="size-5"
                                    src={country.image}
                                    alt={`Illustration of ${country.label}`}
                                  />
                                  <h2 className="font-medium text-black/85">{country.label}</h2>
                                </section>
                                <p className="text-black/60">{country.phone}</p>
                              </DropdownMenuCheckboxItem>
                            ))}
                          </ScrollArea>
                        </DropdownMenuContent>
                      </DropdownMenu>
                      <Input
                        {...field}
                        placeholder={selectedCountry.phone}
                        className="h-sm w-full rounded-sm py-6"
                      />
                    </section>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </article>
          <DialogFooter className="flex-col">
            <Button type="submit" className="w-full" disabled={isPending}>
              Empezar
            </Button>
          </DialogFooter>
        </form>
      </Form>
    </section>
  )
}
