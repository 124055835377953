import { create } from "@/app/state"
import isProd from "@/utils/is-prod"
import type { StateCreator } from "zustand"

interface PresentationState {
  step: number
  setStep: (step: number) => void
}

const state: StateCreator<PresentationState> = (set) => ({
  step: isProd ? 1 : 4,
  setStep: (step: number) => set({ step }),
})

const usePresentationStepper = create<PresentationState>()(state)

export default usePresentationStepper
