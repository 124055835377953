import { useUser } from "@/modules/user/stores"
import { useGetAvailablePlans, useProcessMercadoPago } from "@/modules/plans/queries"
import { useEffect, useState } from "react"
import { PlanCard } from "./plan-card"
import { Button } from "@/components/ui/button"
import { Loader2 } from "lucide-react"
import { cn } from "@/lib/utils"
import { useMediaQuery } from "usehooks-ts"
import { useProcessStripe } from "../queries/use-process-stripe"
import type { AvailablePlan } from "@/services/api/plans/get-all"
import { toast } from "sonner"
import { useNavigate } from "react-router-dom"

export default function PlanSelectionStep() {
  const { user } = useUser()
  const router = useNavigate()
  const isDesktopSize = useMediaQuery("(min-width: 1024px)")
  const [monthlyPlansToggled, setMonthlyPlansToggled] = useState(false)
  const [selectedPlan, setSelectedPlan] = useState<AvailablePlan | null>(null)
  const { processMP } = useProcessMercadoPago()
  const { handleStripeProcess, processStripe } = useProcessStripe()
  const { isPending, isSuccess, data, status } = useGetAvailablePlans()
  const filteredPlans = data?.data.plans.filter((p) => p.duration !== "INFINITY")
  const isCheckoutLoading = processMP.isPending || processStripe.isPending

  const plansToShow = monthlyPlansToggled
    ? filteredPlans
    : filteredPlans?.filter((p) => p.duration === "ONE_YEAR")

  async function handleProcessPayment(plan: AvailablePlan | null) {
    if (plan === null) return
    try {
      if (user.country === "argentina") {
        router(`/plans/${plan.id}/mp-email`)
        return
      }
      await handleStripeProcess(plan.id)
    } catch {
      toast.error("Ha ocurrido un error al comprar el paquete", {
        description: "Por favor intentalo más tarde",
      })
    }
  }

  function handlePlanCard(plan: AvailablePlan) {
    setSelectedPlan(plan)
  }

  useEffect(() => {
    if (isSuccess) {
      const filteredPlans = data?.data.plans.filter((p) => p.duration !== "INFINITY")
      setSelectedPlan(filteredPlans[0])
    }
  }, [status])

  return (
    <div className="w-full px-3 py-4 sm:px-4 sm:py-6">
      {isPending && <Loader2 className="mx-auto my-8 !h-12 !w-12 animate-spin text-primary-300" />}
      {isSuccess && (
        <div
          className={cn(
            "justify-center space-y-6 lg:flex lg:gap-6 lg:space-y-0",
            monthlyPlansToggled && "lg:grid-cols-3",
          )}
        >
          {plansToShow?.map((plan, index) => (
            <PlanCard
              key={index}
              plan={plan}
              selected={selectedPlan?.id === plan.id}
              handlePlanCard={handlePlanCard}
              onClickBtn={() => handleProcessPayment(plan)}
              isBestOffer={plan.best}
              isCheckoutLoading={isCheckoutLoading}
            />
          ))}
        </div>
      )}

      <div
        className={cn(
          "mt-4 space-y-4 sm:mt-6 sm:space-y-6",
          isDesktopSize && "flex justify-center",
        )}
      >
        {!isDesktopSize && (
          <Button
            className="w-full rounded-xl bg-orange-500 py-2 text-sm font-semibold text-white hover:bg-orange-600 sm:py-3 sm:text-base"
            disabled={!selectedPlan}
            onClick={() => handleProcessPayment(selectedPlan)}
          >
            {isCheckoutLoading && <Loader2 className="h-5 w-5 animate-spin" />}
            {isCheckoutLoading ? "Cargando..." : "Comenzar"}
          </Button>
        )}

        <Button
          variant={isDesktopSize ? "ghost" : "secondary"}
          className={cn("w-full", isDesktopSize && "max-w-lg")}
          onClick={() => setMonthlyPlansToggled(!monthlyPlansToggled)}
        >
          {monthlyPlansToggled ? "Volver" : "Ver todos los planes"}
        </Button>
      </div>
    </div>
  )
}
