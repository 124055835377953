import React from "react"
import { cn } from "@/lib/utils"
import { useMediaQuery } from "usehooks-ts"
import { Button } from "@/components/ui/button"
import type { AvailablePlan } from "@/services/api/plans/get-all"
import { addDotsToNumber } from "@/utils/add-dots-to-numbers"
import { useUser } from "@/modules/user/stores"
import { Badge } from "@/components/ui/badge"
import { Loader2 } from "lucide-react"

interface PlanCardProps {
  plan: AvailablePlan
  selected: boolean
  handlePlanCard: (plan: AvailablePlan) => void
  onClickBtn: () => void
  isBestOffer: boolean
  isCheckoutLoading: boolean
}

export const PlanCard: React.FC<PlanCardProps> = ({
  plan,
  selected,
  handlePlanCard,
  onClickBtn,
  isBestOffer,
  isCheckoutLoading,
}) => {
  const { user } = useUser()
  const isDesktopSize = useMediaQuery("(min-width: 1024px)")
  const priceInArs = plan.paymentMethods!.find((method) => method.currency === "ARS")!.price ?? 0
  const priceInUsd = plan.paymentMethods!.find((method) => method.currency === "USD")!.price ?? 0

  return (
    <section
      onClick={() => handlePlanCard(plan)}
      className={cn(
        "group relative min-h-[180px] w-full cursor-pointer rounded-xl p-4 lg:max-w-[328px]",
        "border",
        !isDesktopSize && selected
          ? "ring-none border-none bg-orange-50 shadow-[0px_0px_4px_1px_#FB923C] shadow-primary-500 ring-0"
          : "border-gray-200 hover:border-orange-200",
      )}
    >
      <section className="flex size-full flex-col justify-between space-y-6">
        <section className="flex w-full flex-wrap items-center justify-between gap-2">
          <section className="inline-flex items-center gap-1">
            <img
              src="https://storage.googleapis.com/educabot-aec-cdn-1/tich/images/tich_ai_hat_logo.svg"
              className="size-4 -rotate-[25deg] grayscale"
            />
            <p className="text-xs font-semibold text-black/30">Premium</p>
          </section>
          {isBestOffer && (
            <Badge className="w-fit rounded-[2px] uppercase leading-[14px]">Mejor oferta</Badge>
          )}
        </section>
        <section>
          <h1 className="mb-4 text-xl font-bold text-black">{plan.name}</h1>
          {user.country === "argentina" ? (
            <section className="flex w-full flex-wrap items-center justify-between">
              <h2
                className={cn("flex text-2xl font-bold text-primary", selected && "!text-shadow")}
              >
                <span className="mr-1 mt-1 text-xs uppercase">ars</span>
                {addDotsToNumber(priceInArs)} {plan.duration === "ONE_YEAR" ? "Anual" : "Mensual"}
              </h2>
              {plan.duration === "ONE_YEAR" && (
                <p className={cn("text-sm text-black/60", selected && "font-semibold")}>
                  {`$${priceInArs / 12}/mes`}
                </p>
              )}
            </section>
          ) : (
            <section className="flex items-center justify-between">
              <h2 className={cn("flex text-xl font-bold text-primary", selected && "!text-shadow")}>
                <span className="mr-1 mt-1 text-xs uppercase">usd</span>
                {priceInUsd} {plan.duration === "ONE_YEAR" ? "anual" : "mes"}
              </h2>
              {plan.duration === "ONE_YEAR" && (
                <p className={cn("text-sm text-black/60", selected && "font-semibold")}>
                  {`USD${Math.min(priceInUsd / 12)
                    .toString()
                    .slice(0, 4)}/mes`}
                </p>
              )}
            </section>
          )}
        </section>
        <section className="flex flex-col gap-2">
          <div className="h-px w-full bg-black/30" />
          <div>
            {plan.items.map((item, index) => (
              <p key={index} className="text-sm text-black/60">
                {item}
              </p>
            ))}
          </div>
          {isDesktopSize && (
            <Button className="w-full" onClick={onClickBtn} disabled={isCheckoutLoading}>
              {selected ? (
                <>
                  {isCheckoutLoading && <Loader2 className="h-5 w-5 animate-spin" />}
                  {isCheckoutLoading ? "Cargando..." : "Comenzar"}
                </>
              ) : (
                "Comenzar"
              )}
            </Button>
          )}
        </section>
      </section>
    </section>
  )
}
