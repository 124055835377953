import mixpanel from "mixpanel-browser"
import { SIGN_UP_EVENTS } from "."

export const documents = {
  withSuccess,
  withError,
  omitted,
}
export function omitted() {
  mixpanel.track(SIGN_UP_EVENTS.UPLOAD_DOCUMENT_OMITTED)
}

export function withSuccess() {
  mixpanel.track(SIGN_UP_EVENTS.UPLOAD_DOCUMENT_SUCCESS)
}

interface WithErrorProps {
  error: string
}

export function withError(props: WithErrorProps) {
  mixpanel.track(SIGN_UP_EVENTS.UPLOAD_DOCUMENT_ERROR, props)
}
