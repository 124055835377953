import { client } from "@/config/client"

export interface LifeUsage {
  id: string
  userId: string
  activityCategory: "FLASHCARD" | "CHALLENGE"
  createdAt: string
}

interface GetLifeUsageResponse {
  lifeUsage: LifeUsage[]
}

export function get() {
  return client.get<GetLifeUsageResponse>("/v1/users/life-usage")
}
