import mixpanel from "mixpanel-browser"
import { SIGN_UP_EVENTS } from "."

interface CompletedProps {
  userId: string
  registrationDate: string
  isDocUploaded: boolean
}

export function completed(props: CompletedProps) {
  mixpanel.track(SIGN_UP_EVENTS.COMPLETED, props)
}
